import { Pagination } from "@tryghost/content-api";
import { Button } from "components/atoms/button/button";
import { GetServerSideProps } from "next";
import { useRouter } from "next/router";
import React from "react";
import * as Sentry from "@sentry/nextjs";

import { Arrow } from "components/icons/arrow";
import { PostCard } from "components/mengajar/post-card/post-card";
import { MengajarHomeSEO } from "seo/mengajar/home.seo";
import { getAllPosts, getNavigation } from "services/ghostCMS/contentAPI";
import {
  AllGhostPostOrPages,
  GhostNavigation
} from "services/ghostCMS/ghostTypes";

import {
  MengajarPostsNavigation,
  MengajarHeroContainer,
  MengajarHeroDesc,
  MengajarHeroTitle,
  MengajarPostItem,
  MengajarPostsGrid
} from "styles/pages/mengajar/mengajar.styles";

import { Container } from "styles/common/container";
import { MengajarNavigation } from "components/mengajar/mengajar-navigation/mengajar-navigation";

const Mengajar: React.FC<{
  posts: AllGhostPostOrPages;
  pagination: Pagination;
  navigation: GhostNavigation;
}> = ({ posts, pagination, navigation }) => {
  const router = useRouter();

  return (
    <>
      <MengajarNavigation navigation={navigation} />
      <MengajarHomeSEO />
      <MengajarHeroContainer>
        <MengajarHeroTitle>Mengajar Bersama CoLearn</MengajarHeroTitle>
        <MengajarHeroDesc>
          Wujudkan generasi siap untuk masa depan bersama Guru Juara CoLearn
        </MengajarHeroDesc>
        <Button
          variant="primary"
          onClick={() => window.open("https://colearn.bamboohr.com/careers/22")}
        >
          Daftar sekarang
        </Button>
      </MengajarHeroContainer>
      <Container>
        <MengajarPostsGrid>
          {posts.map((post) => (
            <MengajarPostItem key={post.id}>
              <PostCard post={post} />
            </MengajarPostItem>
          ))}
        </MengajarPostsGrid>

        <MengajarPostsNavigation>
          {pagination.prev && (
            <Button
              variant="ghost"
              disabled={!pagination.prev}
              onClick={() =>
                router.push(`/blog/mengajar/page/${pagination.prev}`)
              }
            >
              <Arrow direction="right" />
            </Button>
          )}
          <p>
            Halaman {pagination.page} dari {pagination.pages}
          </p>
          <Button
            variant="ghost"
            disabled={!pagination.next}
            onClick={() =>
              router.push(`/blog/mengajar/page/${pagination.next}`)
            }
          >
            <Arrow />
          </Button>
        </MengajarPostsNavigation>
      </Container>
    </>
  );
};

export default Mengajar;

export const getServerSideProps: GetServerSideProps = async () => {
  try {
    const posts = await getAllPosts({ limit: 10, page: 1 });
    const navigation = await getNavigation();

    return {
      props: { posts, pagination: posts["meta"].pagination, navigation }
    };
  } catch (error) {
    Sentry.captureException(error);
    console.log("err", error);
    return {
      notFound: true
    };
  }
};
